<template>
  <div class="payments">
    <v-container>
      <v-row>
        <v-switch
          v-model="showFilters"
          label="Mostrar filtros"
          class="mt-0 mb-3"
          color="secondary"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab right color="secondary" small @click="reload">
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              label="Filtre por cedula o nombre de cliente"
              outlined
              hide-details
              dense
              append-icon="mdi-magnify"
              v-model="searchPaymentInput"
              clearable
              clear-icon="mdi-close-circle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-btn block color="secondary" @click="searchSale"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row :justify="role == 'SUPERADMIN' ? 'center' : 'start'">
          <v-col cols="12" sm="6" md="4">
            <VueCtkDateTimePicker
              color="orange"
              v-model="datePiker.model"
              :label="datePiker.label"
              :range="true"
              :custom-shortcuts="datePiker.shortcut"
              :format="datePiker.format"
              :formatted="datePiker.formatted"
              inputSize="sm"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="users"
              item-text="name"
              item-value="id"
              label="Seleccione Usuario"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.user"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.payments_types"
              label="Tipo de pago"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.type"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.payments_methods"
              label="Tipo de pago"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.method"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.payments_origines"
              label="Origin de pago"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.origin"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.office"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn block color="secondary" @click="searchFilters">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-2" v-if="showSummary">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_payments }}
              </v-avatar>
              TOTAL
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.sales_count }}
              </v-avatar>
              TOTAL VENTAS
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.loans_count }}
              </v-avatar>
              TOTAL ALQUILERES
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_initial }}
              </v-avatar>
              PAGO INICIAL
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_credit }}
              </v-avatar>
              PAGO CREDITO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_cash }}
              </v-avatar>
              EFECTIVO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_transfer }}
              </v-avatar>
              TRANSFERENCIA
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_dataphone }}
              </v-avatar>
              DATAFONO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ payment_summary.total_gift_card }}
              </v-avatar>
              TARJETA REGALO
            </v-chip>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            TOTAL <br />
            {{ payment_summary.total_value | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="4">
            TOTAL VENTAS <br />
            {{ payment_summary.total_sales_value | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="4">
            TOTAL ALQUILERES<br />
            {{ payment_summary.total_loans_value | formatNumber }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.payments.payments.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.payments.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
        :items-per-page="20"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.substr(0, 10) }}
        </template>
        <template v-slot:[`item.client`]="{ item }">
          {{
            item.sales.length
              ? item.sales[0].client.full_name
              : item.loans[0].client.full_name
          }}
        </template>
        <template v-slot:[`item.value`]="{ item }">
          {{ item.value | formatNumber }}
        </template>
        <template v-slot:[`item.payment_method`]="{ item }">
          {{ getPaymentMethodsLan(item.payment_method) }}
        </template>
        <template v-slot:[`item.origin`]="{ item }">
          {{ item.sales.length ? "VENTA" : "ALQUILER" }}
        </template>
        <template v-slot:[`item.payment_type`]="{ item }">
          {{ getPaymentTypeLan(item.payment_type) }}
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <v-btn class="mx-2" fab bottom right fixed color="secondary" @click="add">
      <v-icon> mdi-plus </v-icon>
    </v-btn>
    <!-- Dialog add payment -->
    <v-dialog v-model="dialogAddPayment" width="800" persistent>
      <v-card>
        <v-card-title>Agregar Pago</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formPayment" v-model="formPayment">
            <v-container>
              <v-row>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="model.origin"
                    label="ORIGEN"
                    :items="['ALQUILER', 'VENTAS']"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="model.reference"
                    :rules="[rules.required]"
                    label="Número de contrato"
                    append-outer-icon="mdi-account-search"
                    @click:append-outer="searchData"
                    required
                    type="number"
                    clear-icon="mdi-close-circle"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="model.client.full_name"
                    label="CLIENTE"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersPayment"
                    :items="model.payments"
                    class="elevation-1 mb-7"
                    no-data-text="No hay registros agregados..."
                    hide-default-footer
                  >
                    <template v-slot:[`item.value`]="{ item }">
                      {{ item.value | formatNumber }}
                    </template>
                    <template v-slot:[`item.payment_method`]="{ item }">
                      {{ getPaymentMethodsLan(item.payment_method) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <h3>TOTAL ALQUILER / VENTA</h3>
                  <span>{{ model.total | formatNumber }}</span>
                </v-col>
                <v-col cols="4">
                  <h3>TOTAL PAGADO</h3>
                  <span>{{ model.total_payments | formatNumber }}</span>
                </v-col>
                <v-col cols="4">
                  <h3>DEUDA</h3>
                  <span>{{
                    (model.total - model.total_payments) | formatNumber
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="modelPayment.value"
                    label="Valor"
                    :rules="[rules.required]"
                    :disabled="disabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="modelPayment.payment_method"
                    label="Metodo de pago"
                    :rules="[rules.required]"
                    :items="this.$store.state.payments.paymentMethods"
                    :disabled="disabled"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" v-if="multilanguage">
                  <v-select
                    :items="idioms"
                    v-model="modelPayment.lan"
                    :rules="[rules.required]"
                    label="Idioma"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="disabled"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_PAYMENTS } from "@/store/payments";
import { SET_LOAN } from "@/store/loan";
import { SET_SALE } from "@/store/sale";
import { SET_ALERT } from "@/store/alert";
import { SET_USERS } from "@/store/user/users";
import { SET_PAYMENT_SUMMARY } from "@/store/summaries";
import { SET_OFFICES } from "@/store/offices";
import { SET_USER } from "@/store/user";
import { mapGetters } from "vuex";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
export default {
  name: "Users",
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    showFilters: false,
    showSummary: false,
    dialogAddPayment: false,
    formPayment: true,
    disabled: false,
    search: "",
    headers: [
      {
        text: "Fecha",
        align: "start",
        filterable: true,
        value: "created_at",
      },
      {
        text: "Cliente",
        align: "start",
        filterable: true,
        value: "client",
      },
      {
        text: "Valor",
        align: "start",
        filterable: true,
        value: "value",
      },
      {
        text: "Metodo de pago",
        align: "start",
        filterable: true,
        sortable: false,
        value: "payment_method",
      },
      {
        text: "Origen",
        align: "start",
        filterable: true,
        sortable: false,
        value: "origin",
      },
      {
        text: "Tipo",
        align: "start",
        filterable: true,
        sortable: false,
        value: "payment_type",
      },
    ],
    headersPayment: [
      { text: "Valor", value: "value", sortable: false },
      { text: "Método de pago", value: "payment_method", sortable: false },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    model: {
      currentObject: null,
      origin: "",
      reference: "",
      client: {
        full_name: "",
      },
      total_payments: 0,
      total: 0,
      payments: [],
    },
    modelPayment: {
      loan_id: "",
      sale_id: "",
      value: "",
      payment_method: "",
      lan: "es",
    },
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    datePiker: {
      model: null,
      label: "SELECCIONE EL RANGO DE FECHA *",
      format: "YYYY-MM-DD",
      outputFormat: "YYYY-MM-DD",
      formatted: "ll",
      shortcut: [
        { key: "now", label: "Hoy", value: 0 },
        { key: "thisWeek", label: "Esta semana", value: "isoWeek" },
        { key: "last7Days", label: "Ultimos 7 dias", value: 7 },
        { key: "last30Days", label: "Ultimos 30 dias", value: 30 },
        { key: "thisMonth", label: "Este mes", value: "month" },
        { key: "lastMonth", label: "Ultimo mes", value: "-month" },
        { key: "thisYear", label: "Este año", value: "year" },
      ],
    },
    filters: {
      user: "",
      type: "",
      method: "",
      origin: "",
      office: "",
    },
    idioms: [
      { text: "Español", value: "es" },
      { text: "Ingles", value: "en" },
    ],
    searchPaymentInput: "",
    searchFiltersText: "",
    users: [],
    offices: [],
    payment_summary: null,
    multilanguage: 0,
  }),
  created() {
    this.$store.dispatch("getPayments");
    this.$store.dispatch("getUsersAll");
    this.$store.dispatch("getOfficesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_PAYMENTS) {
        this.pagination.current = state.payments.payments.current_page;
        this.pagination.total = state.payments.payments.last_page;
      }
      if (mutation.type === SET_LOAN) {
        this.setData(state.loan.loan);
      }
      if (mutation.type === SET_SALE) {
        this.setData(state.sale.sale);
      }
      if (mutation.type === SET_USERS) {
        this.users = state.user.users.users;
      }
      if (mutation.type === SET_PAYMENT_SUMMARY) {
        this.payment_summary = state.summaries.payment_summary;
        this.showSummary = true;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
      if (mutation.type === SET_USER) {
        this.multilanguage = state.user.user.office.multilanguage;
      }
    });
  },
  computed: {
    ...mapGetters(["getPaymentTypeLan", "getPaymentMethodsLan"]),
    value() {
      return this.modelPayment.value;
    },
    role() {
      return this.$store.state.user.user.role;
    },
  },
  watch: {
    dialogAddPayment: function () {
      if (!this.dialogAddPayment) {
        this.close();
      }
    },
    value(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.modelPayment.value = new Intl.NumberFormat("de-DE").format(val);
      }
    },
  },
  methods: {
    reload() {
      this.searchPaymentInput = "";
      this.filters = {
        user: "",
        type: "",
        method: "",
        origin: "",
      };
      this.datePiker.model = null;
      this.searchFiltersText = "";
      this.showFilters = false;
      this.showSummary = false;
      this.$store.dispatch("getPayments");
    },
    searchSale() {
      if (this.searchPaymentInput == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }
      this.showFilters = false;
      this.$store.dispatch(
        "getPayments",
        "search=" + this.searchPaymentInput + "&"
      );
    },
    searchFilters() {
      this.searchFiltersText = "";

      if (this.datePiker.model !== null) {
        this.searchFiltersText =
          "min_date=" +
          this.datePiker.model.start +
          "&max_date=" +
          this.datePiker.model.end +
          "&";
      }

      if (this.filters.user !== "") {
        this.searchFiltersText += "user=" + this.filters.user + "&";
      }

      if (this.filters.type !== "") {
        this.searchFiltersText += "type=" + this.filters.type + "&";
      }

      if (this.filters.method !== "") {
        this.searchFiltersText += "method=" + this.filters.method + "&";
      }

      if (this.filters.origin !== "") {
        this.searchFiltersText += "has=" + this.filters.origin + "&";
      }

      if (this.filters.office !== "") {
        this.searchFiltersText += "office=" + this.filters.office + "&";
      }

      if (this.searchFiltersText == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }

      this.showFilters = false;
      this.$store.dispatch("getPaymentSummary", this.searchFiltersText);
      this.$store.dispatch("getPayments", this.searchFiltersText);
    },
    nextPage() {
      this.$store.dispatch("nextPagePayments", this.pagination.current);
    },
    add() {
      this.dialogAddPayment = true;
    },
    searchData() {
      this.disabled = false;
      const functionName =
        this.model.origin == "ALQUILER" ? "getLoanFilters" : "getSaleFilters";
      this.$store.dispatch(functionName, "reference=" + this.model.reference);
    },
    setData(data) {
      if (!data) {
        this.$store.commit(SET_ALERT, {
          message: "No se encontro registro con este número",
          type: "info",
        });
        return;
      }
      this.model.payments = data[0].payments;
      this.model.currentObject = data[0];
      this.model.client.full_name = data[0].client.full_name;
      this.model.total =
        this.model.origin == "ALQUILER"
          ? data[0].rental_value
          : data[0].sale_value;
      if (this.model.origin == "ALQUILER") {
        this.modelPayment.loan_id = data[0].id;
      } else {
        this.modelPayment.sale_id = data[0].id;
      }
      this.validateTotalPayments();
    },
    validateTotalPayments() {
      this.model.total_payments = 0;
      this.model.currentObject.payments.forEach((element) => {
        this.model.total_payments += parseFloat(element.value);
      });
      if (this.model.total_payments == this.model.total) {
        this.$store.commit(SET_ALERT, {
          message:
            "La deuda esta a paz y salvo, no se puede agregar nuevos pagos.",
          type: "info",
        });
        this.disabled = true;
      }
    },
    close() {
      this.model = {
        currentObject: null,
        reference: "",
        client: {
          full_name: "",
        },
        total_payments: 0,
        total: 0,
        payments: [],
      };
      this.modelPayment = {
        loan_id: "",
        sale_id: "",
        value: "",
        payment_method: "",
        lan: "es",
      };
      this.dialogAddPayment = false;
      this.disabled = false;
      this.$refs.formPayment.resetValidation();
    },
    save() {
      if (!this.$refs.formPayment.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      this.modelPayment.value = this.modelPayment.value.split(".").join("");
      this.$store.dispatch("createPayment", this.modelPayment);
      this.close();
    },
  },
  components: {
    VueCtkDateTimePicker,
  },
};
</script>
